.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 5vmin;
  font-size: 15px;
}
.header__block {
  background: white;
  height: 1vmin;
  width: 1vmin;
}
.header__nav_item {
  display: inline-block;
} 

.header__nav_item a {
  color: whitesmoke;
  padding: 0 1vmin;
  text-decoration: none;
}

.header__nav_item.active,
.btn.active {
  opacity: 0.5;
}

#header .btn {
  border: solid 2px white;
  background: transparent;
  text-transform: uppercase;
}

@media screen and (max-width: 768px)  {
  .header {
    padding-top: 10vmin;
  }
  .header__nav {
    display: none;
  }
  .header__nav_mobile{
    font-size: 12px;
    text-align: center;
  }
  .header__nav_mobile ul{
    padding: 0;
  }
  .header__nav_item {
    margin: 0 5px;
  }
  #header .btn{
    font-size: 10px;
  }
}

@media screen and (min-width: 769px) {
  .header__nav_mobile {
    display: none;
    text-align: center;
  }
  .header__isotype {
    max-width: 4vmin;
  }
  #header .btn {
    font-size: 1.4vmin;
   }
}