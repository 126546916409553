.animated__container {
  position: relative;
  max-width: 100vw;
}

.animated__items_container {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.animated__block_container {
  position: relative;
  z-index: 1;
}