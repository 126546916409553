#downloads .events__download {
  background: white;
}


  #downloads .events__download {
    padding-top: 5vmin;
  }
  #downloads .events__hero {
    height: 15vh;
    padding-bottom: 260px;
  } 
