.embed__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px );
  height: 100%;
}

.embed__container.active:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px );
  height: 100%;
}

.video_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px );
  height: 100%;
}