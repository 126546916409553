.privacy .data__content * {
  font-family: InterMedium;
}

@media (max-width: 768px) {
  .privacy .about__hero {
    padding-bottom: 19vmin;
  }
  .privacy .data__content {
    padding-bottom: 15vmin;
  }
}

@media (min-width: 769px) {
  .privacy .about__hero {
    padding-bottom: 5vmin;
  }
  .privacy .data__content {
    padding-bottom: 15vmin;
  }
}