.footer * {
  color: rgb(255, 255, 255, 1);
}
.footer {
  overflow: hidden;
  max-width: 100vw;
}

.footer__background {
  background: url(../../assets/images/footer_gradient_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
}
.footer_info_suscription h3, .footer_info_contact h3{
  font-size: 3.5vmin;
}
.social_networks {
  display: flex;
  margin-bottom: 30px;
}
.social_networks div {
  margin-right: 2vmin;
  font-size: 3vmin;
}
.footer_legal{
  margin-bottom: 10px;
}

.footer_info_suscription ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

.footer_info_suscription :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

.footer_info_suscription ::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}

@media (max-width: 768px) {
  .footer {
    background-position: top center;
    padding-top: 20vh !important;
  }
  .footer_info_suscription h3, .footer_info_contact h3{
    font-size: 5vmin;
  }
}
@media(max-width:600px){
  .footer_legal{
    font-size: 10px;
    text-align: center;
  }
  .footer_legal span{
    display: block;
  }
  .footer {
    padding-top: 15vh !important;
  }
  .footer .home__hero_video_form {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin: 2.5vh 0;
  }
  .footer .home__hero_video_form .text_input{
    margin: 0vh 15px 0 0; 
  }
}

@media (min-width: 769px) {
  
  
  .footer__background {
    background-position: top center;
  }
  .footer {
    padding-top: 25vh !important;
    padding-bottom: 5vh;
  }
  .footer_legal {
    display: flex;
    justify-content: space-between;
    margin-top: 15vh;
  }
  .footer_info {
    display: flex;
    justify-content: space-between;
  }
  .footer_info_suscription {
    width: 40%;
  }
  .footer_info_suscription input {
    background: rgb(255, 255, 255, 0.5);
    border: solid 2px white;
  }
  .footer .home__hero_video_form{
    padding-right: 25%;
  }
}